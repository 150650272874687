.exit-survey-page {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .exit-survey-page h1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .exit-survey-page p {
    font-size: 18px;
    color: #666;
    margin-bottom: 30px;
  }
  
  .exit-survey-page .tree-renderer {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .exit-survey-page .question {
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .exit-survey-page .option {
    margin-bottom: 10px;
  }
  
  .exit-survey-page .option label {
    font-size: 16px;
    color: #666;
  }
  
  .exit-survey-page .option input[type="radio"] {
    margin-right: 10px;
  }
  
  .exit-survey-page .submit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .exit-survey-page .submit-button:hover {
    background-color: #0056b3;
  }