.service-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .quantity-control {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  
  .quantity-control button {
    width: 25px;
    height: 25px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .quantity-control input {
    width: 40px;
    text-align: center;
    margin: 0 5px;
  }
  