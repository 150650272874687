.intro-section {
  display: flex;
  flex-direction: column; /* Image below text on mobile */
  align-items: center;
  text-align: center;
  color: #0f9e82; /* Adjust the color to match your brand */
  min-height: 750px;
}
.intro-desc{
  color:rgb(9, 55, 31);
  font-weight: 300;
}
.intro-content {
  flex: 1;
  padding: 20px;
  padding-top: 92px;
}
.intro-content h1 {
  font-size: 32px;
  letter-spacing: 6px;
  font-weight: 700;
  line-height: 1;
  margin-top:0px;
  text-transform: uppercase;
}

.intro-details {
  margin: 20px 0;
  font-weight: 800;
  letter-spacing: 3px;
  font-size: 16px;
}

.intro-buttons {
  display: flex;
  flex-direction: column; /* Stack buttons vertically on mobile */
  width: 100%;
}

.intro-buttons button {
  background-color: #ff403f; /* Button color */
  color: white;
  padding: 10px;
  margin: 10px auto;
  border: none;
  cursor: pointer;
  width:244px;
  font-size: 14px;
  min-height: 41px;
  font-weight: 600;
  letter-spacing: 1px;
}

.intro-image img {
  width: 100vw;
  /* max-width: 320px; Adjust the size as needed */
  height: auto;
  max-height: 260px;
  object-fit: cover;
  margin-bottom: -9px;
}

@media (min-width: 768px) { /* Adjust breakpoint as needed */
  .intro-section {
    flex-direction: row; /* Image on the right on desktop */
  }

  .intro-content, .intro-image {
    flex-basis: 50%;
  }

  .intro-buttons {
    flex-direction: row; /* Side by side buttons on desktop */
    justify-content: center;
  }

  .intro-buttons button {
    margin: 0 10px; /* Space between buttons on desktop */
    font-size: 16px;
  }

  .intro-image img {
    max-width: none; /* Full size image on desktop */
    width:100%;
    max-height: none; /* Full size image on desktop */
  }
  .intro-content h1 {
    font-size: 50px;
    letter-spacing: 6px;
    font-weight: 700;
    line-height: 1;
    margin-top:0px;
    max-width: 500px;
    margin:auto;
    text-transform: uppercase;
  }

  .intro-details {
    margin: 20px 0;
    font-weight: 800;
    letter-spacing: 3px;
    font-size: 18px;
  }
  
}
