.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff; /* Adjust color as needed */
  padding: 1rem;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2);
  min-height: 60px;
}

.navbar-logo img {
  height: 50px; /* Adjust size as needed */
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 20px; /* Adjust spacing as needed */
}

.navbar-links a {
  text-decoration: none;
  color: #000; /* Adjust link color as needed */
  font-weight: bold;
}
