/* TreeSessionViewer.css */

.tree-session-viewer {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .tree-session-viewer h1 {
    color: #333;
    text-align: center;
  }
  
  .tree-session-viewer select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .date-range-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .date-input {
    padding: 10px;
    font-size: 16px;
    width: 45%;
  }
  
  .summary-container {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .search-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .search-input {
    padding: 10px;
    font-size: 16px;
    width: 30%;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .search-input {
      width: 100%;
    }
  }
  
  .search-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #45a049;
  }
  
  .session-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: white;
  }