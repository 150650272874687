/* styles.css */

.admin-calendar-container {
  padding: 20px;
}

.adminInputField {
  margin-top: 5px;
  margin-bottom: 5px;
}

.adminInputSection table, 
.adminInputSection th,
.adminInputSection td {
  border: 0px solid white; /* Border for each cell, providing the dividing lines */
  padding: 0px;
}

.adminPageTitle {
  text-align: center;
}

.adminPageTitle h1 {
  margin: 0;
}

.adminPageTitle p {
  margin-top: 0;
  margin-bottom: 10px;
}

.adminParent {
  margin-left: 10px;
}

.adminParent button {
  margin: 6px;
  padding: 5px 10px;
}

.adminParent input {
  margin-bottom: 10px;
}

.adminParent label {
  margin-top: 5px;
}

.adminTabSection {
  /* border: 1px solid; */
  /* padding: 10px; */
}

.adminTabSection h3 {
  margin-top: 0;
  margin-bottom: 3px;
  font-size: medium;
}

.adminTable table {
  border-collapse: collapse; 
  border: 2px solid black;
}

.adminTable th, 
.adminTable td {
  border: 1px solid black; 
  padding: 2px; 
  text-align: left; 
}

.adminTextField {
  border: 1px solid;
  padding: 5px;
  /* margin-left: 5px; */
  margin-bottom: 10px;
}

.alert {
  color: red;
}

.alert-success {
  color: green;
}

.bookingDetailTopRight {
  margin: 5px;
  font-size: 12px;
}

.button {
  padding: 5px 10px; 
  font-size: 16px;
  margin: 10px 0;
}

.calendar-top-space, .calendar-bottom-space {
  margin: 10px 0;
}

.green {
  background-color: green !important;
  color: white !important;
}

.inventoryPage {
  margin: 5px;
}

.inventoryPage table {
  border-collapse: collapse;
  padding: 8px;
  text-align: left;
  width: 100%;
  overflow-x: auto;
}

.inventoryPage th {
  padding: 0px;
  font-size: smaller;
  border: 1px solid #ddd;
  max-width: 80px;
}

.inventoryPage td {
  padding: 0px;
  font-size: smaller;
  border: 1px solid #ddd;
  max-width: 80px;
  overflow-x: auto;
}

.inventoryPage tr:nth-child(even) {
  background-color: #f2f2f2;
}

.inventoryPage input {
  max-width: 50px;
}

.inventoryPage td button {
  padding: 0px 8px;
  margin: 5px;
}


.monthlySchedule {
  margin: 5px;
}

.monthlySchedule table {
  border-collapse: collapse;
  padding: 8px;
  text-align: left;
  width: 100%;
}

.monthlySchedule th {
  padding: 0px;
  font-size: smaller;
  border: 1px solid #ddd;
  max-width: 110px;
}

.monthlySchedule td {
  padding: 0px;
  font-size: smaller;
  border: 1px solid #ddd;
  max-width: 110px;
}

.monthlySchedule tr:nth-child(even) {
  background-color: #f2f2f2;
}

.monthlySchedule input {
  max-width: 50px;
}

.monthlySchedule td button {
  padding: 0px 8px;
  margin: 5px;
}


.rotateScreen {
  display: none;
}

@media screen and (max-width: 399px) {
  .rotateScreen {
    display: block;
    color: red;
  }
}


.navigate-button {
  padding: 8px 8px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.navigate-button:hover {
  background-color: #0056b3;
}

.red {
  background-color: red !important;
  color: white !important;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
}

.results-table th,
.results-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.results-table th {
  background-color: #f2f2f2;
}

.results-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.results-table tr:hover {
  background-color: #e9e9e9;
}

.saveButton {
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
}

.search-controls {
  margin-bottom: 20px;
}

.search-controls button {
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-controls button:hover {
  background-color: #0056b3;
}

.search-controls input,
.search-controls select {
  padding: 8px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.todo {
  color: red;
}

.user-booking-search {
  font-family: Arial, sans-serif;
}

.chart-container{
  max-width: 800px;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-day {
  border: 1px solid #ddd;
  padding: 5px;
  min-height: 100px;
}

.date {
  font-weight: bold;
  margin-bottom: 5px;
}

.schedules {
  font-size: 0.8em;
}

.schedule-item {
  margin-bottom: 2px;
}