.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  height: 75px;
  padding: 8px 16px;
  /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); */
  
}

.navbar-logo {
  height: 60px;
  margin-left: 12%;
}

.navbar-logo-img {
  height: 60px;
}

.navbar-text-links {
  display: flex;
  align-items: center;
  
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  right: 0;
  top: 100%;
  text-align: right;
  width: 350px;
}
.dropdown-content a {
  display: block; /* This will put each link on its own line */
  color: rgb(15, 158, 130); /* Text color */
  text-decoration: none; /* Remove underline from links */
}
.dropdown-content a:hover {
  color: black; /* Color for hover state */
  /* background-color: #e8e8e8; */
}

.dropdown-content a:not(:last-child) {
  border-bottom: 1px solid rgb(15, 158, 130);
}
.dropdown:hover .dropdown-content {
  display: block;
}

.navigationToggle {
  display: none; 
  cursor: pointer;

}

.navbar-buttons {
  display: flex;
  justify-content: flex-end; 
}

.navbar-links-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-button {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 222px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-color: #FF4040;
  color:white;
  font-size: 20px;
  margin-right: 30px;
  font-weight: 400;
  padding: 10px 20px;
  cursor: pointer;
}

.navbar-button:hover{
  border: 2px solid rgba(0, 0, 0, 1);
  background-color: white;
  color:black;
  transition: all 0.2s ease, visibility 0s;
}

.navbar-text-links{
   font-size: 24px;
   font-weight: 200;
  color: rgb(15, 158, 130);
  margin-left: 55px;
}

.navbar-text-links a{
  margin:10px;
  text-decoration: none;
  color: rgb(15, 158, 130);
}


.navbar-menu.closed {
  display: none;
}


@media screen and (max-width: 768px) {
  .navbar-logo {
    height: 40px;
    margin-left: 0%;
  }
  .navbar-logo-img {
    height: 40px;
  }
  .navbar-links-container {
    display: none; 
  }

  .navbar-text-links,
  .navbar-buttons {
    display: none; 
  }


.navigationToggle {
  display: block; 
  cursor: pointer;
  z-index: 1000; 
  position: fixed;
  right: 16px; 
  top: 16px; 
}


.line {
  background-color: rgb(85,85,85); 
  height: 2.5px;
  margin-bottom: 5px;
  width: 30px; 
  display: block; 
}

.navbar-menu {
  flex-direction: column; 
}


.navbar-menu.expanded {
  display: flex;
  position: absolute;
  top: 55px; 
  left: 0;
  width: 100vw;
  background-color: #fff;
  padding: 0;
  margin: 0;
  z-index: 999; 
  text-align: right; 
  padding-right: 22px;
  flex-direction: column;
  box-shadow: 0 8px 10px -5px rgb(0 0 0 / 16%);
  
}
  
.navbar-menu a:not(:last-child) {
  border-bottom: 1px solid rgb(15, 158, 130);
}

.navbar-menu a{
  font-weight: 200;
  display: block; 
  padding: 0px 15px; 
  color: rgb(15, 158, 130); 
  text-decoration: none; 
}
.navbar {
  /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); */
  height: 60px;
  }

}