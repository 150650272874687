.tab-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    border-bottom: 2px solid #ccc;
  }
  
  .tab-content {
    padding: 10px;
    border: 1px solid #ccc;
    border-top: none;
  }
  
  .tab-list-item {
    padding: 1px 2px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-bottom: none;
    background-color: #f0f0f0;
    margin-right: 4px; /* Space between tabs */
    transition: background-color 0.3s ease;
  }
  
  .tab-list-item:hover {
    background-color: #e0e0e0; /* Lighter grey on hover */
  }
  
  .tab-list-active {
    background-color: white;
    border-bottom: 2px solid white; /* Cover up border under active tab */
    position: relative; /* Ensure it's above the content box border */
  }
  