@import url('https://fonts.googleapis.com/css?family=Prompt:100,200,300,400,500,600,700,800,900&display=swap');
/* @import url('https://fonts.googleapis.com/css?family=Questrial&display=swap'); */

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Prompt", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
  src: local('Pacifico Regular'), local('Pacifico-Regular'),
  url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2)
    format('woff2');
}


h5{
    margin:0px;
    font-size: 100%;
}

a{
    text-decoration: underline;
}

.social-proof-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #727272;
    color:white;
    padding: 1rem;
  }
  
  .star-rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
  }
    
  .google-logo{
    height: 30px !important;
    width: auto!important;
    margin-top: 5px;
  }
  
  
  .star-rating img {
    height: 20px;
    width: 20px;
    margin-right: 0.25rem;
  }

  .greenTitle{
    text-transform: uppercase;
    color:#0F9E82;
    font-size: 50px !important;
    font-weight: 700;
    margin-bottom: 0px;
    padding-top: 15px;
      margin-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
    letter-spacing: 7px;
  }
  .whiteTitle{
    text-transform: uppercase;
    color:white;
    font-size: 50px !important;
    font-weight: 700;
    margin-bottom: 0px;
    padding-top: 15px;
    margin-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
    letter-spacing: 7px;
  }
  .subtitle {
    margin-bottom: 20px;
    line-height: 1.5 !important;
    /* color:#09371F; */
    font-size: 16px;
    font-weight: 200;
    margin-top:0px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .bookOnlineButton{
    margin-top: 25px;
    margin-bottom: 25px;
    width: 172px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-color: #FF4040;
    color:white;
    font-size: 18px;
  }
  .bookOnlineButton:hover{
    border: 2px solid rgba(0, 0, 0, 1);
    background-color: white;
    color:black;
    transition: all 0.2s ease, visibility 0s;
  }
.treatmentPageHeader{
  padding-top: 8vh;
}

  @media only screen and (max-width: 768px) {
    .greenTitle{
      color:#0F9E82;
      font-size: 32px !important;
      font-weight: 700;
      margin-bottom: 0px;
      padding-top: 15px;
      margin-top: 0px;
      padding-left: 30px;
      padding-right: 30px;
      letter-spacing: 7px;
    }
    .treatmentPageHeader{
      padding-top: 55px;
    }
    
    .whiteTitle{
      color:white;
      font-size: 32px !important;
      font-weight: 700;
      margin-bottom: 0px;
      padding-top: 15px;
      margin-top: 0px;
      padding-left: 30px;
      padding-right: 30px;
      letter-spacing: 7px;
    }
  }

  .sectionhr{
    color: black;
    background-color: black;
    height: .75px;
    margin-top: 0px;
    margin-bottom: 0px;
  }