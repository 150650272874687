.payment-container {
    max-width: 600px;
    margin: 0 auto;
    margin-left:0;
    padding: 15px;
    text-align: left;
    padding-top: 80px;
  }
  
  .service-btn {
    background-color: rgb(15, 158, 130);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 17px;
    font-size: 16px;
  }
  
  .service-btn:hover {
    background-color: #0d896f;
  }
  
  .total-amount {
    margin-top: 15px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .tip-input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .tip-input label {
    margin-right: 10px;
  }
  
  .tip-input input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .sq-fieldset {
    margin-top: 20px;
  }
  
  .payment-status {
    margin-top: 15px;
    color: #006400;
  }
  
  .notes {
    color: #888;
    font-style: italic;
    margin-top: 20px;
  }
  
  .services-table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .services-table th, .services-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .services-table th {
    background-color: #0F9E82;
    color: white;
  }
  
  .services-table tr:nth-child(even) { background-color: #f8f9fc; }
  
  .services-table tr:hover { background-color: #ddd; }
  
  .services-table .total-label {
    text-align: right;
    background-color: #f8f9fc;
    font-size: 16px;
  }
  
  .services-table .total-value {
    font-weight: bold;
    font-size: 16px;
  }
  
  /* Style for the remove button */
  .services-table button {
    padding: 5px 10px;
    background-color: #e74a3b;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Hover effect for the remove button */
  .services-table button:hover {
    background-color: #d26a5c;
  }
  
  /* Additional styles for alignment and padding */
  .services-table td {
    vertical-align: middle;
  }
  
  /* Style adjustments for the payment form */
  .sq-fieldset {
    border: none;
    padding: 0;
  }
  
  /* Additional styling for the credit card input */
  .sq-input {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
  }
  
  /* Styling for the payment button */
  .sq-payment-button {
    background-color: #0F9E82;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
  }
  
  /* Hover effect for the payment button */
  .sq-payment-button:hover {
    background-color: #0d896f;

  }

  .c-jWYnUm{
    background-color: #0d896f;
  }

  .tip-input{
    width:50px;
  }
  .tip-button{
    width:100%;
    justify-self: center;
  }
  .red{
    background-color: red !important;
    color: white !important;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dim the background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
  